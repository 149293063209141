import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"


const IaidoPage = ({ data }) => (
  <Layout>
    <SEO title="Iaido" />

    <div style={{ maxWidth: `1200px`, marginBottom: `1.45rem` }}>
      <h1>LE IAIDO</h1>
     
      <h2>Principe:</h2>
      <p>Le Iaido est un art martial fondé sur l'aptitude à dégainer et à trancher d'un seul mouvement à l'aide d'un katana. Il s'agit alors pour le combattant de pouvoir faire face rapidement à n'importe quelle situation de danger. Les techniques sont enseignées sous forme de Kata, un ensemble de mouvements codifiés figurant une simulation de combat contre un ou plusieurs adversaires. </p>
      <p>Le Iaido se pratique avec une lame véritable, aussi il est majoritairement pratiqué seul par soucis de sécurité. les Katas sont pratiqués dans le calme et le silence, ce qui fait que cet art se focalise grandement sur le contrôle de soit et la concentration. </p>
      <p>En plus de l'enseignement des 12 katas communs du Setei Iai, les KICN pratique la forme de Ko-Ryu (école ancienne) du Tamiya Ryu. </p>

      <h2>Pratique:</h2>
      <p>Le matériel nécessaire aux débutant est assez simple. Il faut simplement une tenue de sport légère et une paire de genouillère de sport. Les genouillères sont indispensables car la pratique se fait sur un parquet, et souvent à genoux. L'arme en bois d'entraînement sera quant à elle prêtée durant les premiers cours de pratiques ou jusqu'à l'achat par la pratiquant. Notez que la tenue ne sera pas nécessaire en début de pratique. </p>
      <p>N.B. Il est fortement déconseillé d'acheter un sabre de qualité inférieure à un Iaito pour pratiquer.</p>
      <p>De plus les sabres affutés sont strictements interdits pour l'entraînement compte tenu du risque de blessures élevé. </p>

      <h2>Note Historique:</h2>
      <p>Les premières techniques de Iaijutsu sont apparues entre le XIVème et le XVIIème siècle, une époque de guerres où le danger était permanent pour les samouraïs. Il a alors fallu élaborer des techniques de défense rapides et efficaces. Les techniques seront codifiées durant le XVIème siècle et rapidement différentes écoles (Ko-Ryu) vont faire leur apparition. </p>
      <p>Depuis la fin du XIXème siècle le Japon est pacifié et le iaijutsu devint peu à peu le Iaido au début du XXème siècle. En 1961 on créa les Katas communs à tous les pratiquants nommés Setei-Iai, encore enseignés dans tous les dojos. Cependant chaque pratiquant peut bénéficier de l'enseignement du Ko-Ryu spécifique à son école. </p>


    </div>

  </Layout>
)

export default IaidoPage
